<script setup lang="ts">
import { cn } from '@/lib/utils.ts';
import { NavigationMenuViewport, type NavigationMenuViewportProps, useForwardProps } from 'radix-vue';
import { type HTMLAttributes, computed } from 'vue';

const props = defineProps<NavigationMenuViewportProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <div class="nav-viewport-container absolute w-full">
    <NavigationMenuViewport v-bind="forwardedProps" class="nav-viewport p-4" :class="cn('flex', props.class)" />
  </div>
</template>
