<script setup lang="ts">
import RouterLink from '@/components/RouterLink.vue';
import CartButton from '@/components/cart/CartButton.vue';
import LogoHorizontalSymbol from '@/components/icons/LogoHorizontalSymbol.vue';
import { Label } from '@/components/ui/label';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { onMounted, onUnmounted, ref, useTemplateRef } from 'vue';
import Menu from '~icons/lucide/menu';

const container = useTemplateRef('header-container');
 
const MIN_HEADER_HEIGHT = '72px';

function onResize() {
  if (typeof window === 'undefined') return;
  container.value?.style.setProperty('--h', MIN_HEADER_HEIGHT);
  container.value?.style.setProperty('height', MIN_HEADER_HEIGHT);

  requestAnimationFrame(() => {
    // remove the height style so it can be calculated again
    container.value?.style.removeProperty('height');

    requestAnimationFrame(() => {
      container.value?.style.setProperty('--h', `${document.documentElement.scrollHeight}px`);
    });
  });
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

const navHeight = ref(0);

const nav = ref('');
</script>

<template>
  <div ref="header-container" class="header-container">
    <header>
      <div class="hidden md:block"></div>
      <RouterLink to="/" class="md:hidden text-white hover:text-accent transition-colors">
        <LogoHorizontalSymbol height="24" />
      </RouterLink>

      <NavigationMenu
        class="navigation"
        v-model="nav"
        :data-open="!!nav"
        @size="(v) => (navHeight = v)"
        :style="{ '--tt': navHeight }"
      >
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger>Boards</NavigationMenuTrigger>
            <NavigationMenuContent>
              <!-- <NavigationMenuLink as-child><RouterLink to="/boards">All Boards</RouterLink></NavigationMenuLink> -->
              <NavigationMenuLink as-child>
                <RouterLink to="/products/aero-pro">Aero Pro</RouterLink>
              </NavigationMenuLink>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuTrigger>Accessories</NavigationMenuTrigger>
            <NavigationMenuContent>
              <!-- <NavigationMenuLink as-child>
                <RouterLink to="/accessories">All Accessories</RouterLink>
              </NavigationMenuLink> -->
              <NavigationMenuLink as-child>
                <RouterLink to="/products/600w-fast-charger">600W Fast Charger</RouterLink>
              </NavigationMenuLink>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuTrigger>Info</NavigationMenuTrigger>
            <NavigationMenuContent>
              <NavigationMenuLink as-child><RouterLink to="/policies">Policies</RouterLink></NavigationMenuLink>
              <NavigationMenuLink as-child><RouterLink to="/terms">Terms & Conditions</RouterLink></NavigationMenuLink>
              <NavigationMenuLink as-child><RouterLink to="/about">About Us</RouterLink></NavigationMenuLink>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuTrigger>Contact</NavigationMenuTrigger>
            <NavigationMenuContent>
              <!-- <NavigationMenuLink as-child><RouterLink to="/media">Media</RouterLink></NavigationMenuLink>
              <NavigationMenuLink as-child><RouterLink to="/wholesale">Wholesale</RouterLink></NavigationMenuLink>
              <NavigationMenuLink as-child><RouterLink to="/affiliate">Affiliate</RouterLink></NavigationMenuLink> -->
              <NavigationMenuLink as-child><RouterLink to="/support">Support</RouterLink></NavigationMenuLink>
            </NavigationMenuContent>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>

      <div class="flex gap-4">
        <Popover>
          <PopoverTrigger class="md:hidden h-full aspect-square flex items-center justify-center">
            <Menu class="text-white" font-size="24" />
          </PopoverTrigger>
          <PopoverContent :align-offset="24" class="md:hidden">
            <nav class="small-nav">
              <div>
                <Label>Boards</Label>
                <!-- <RouterLink to="/boards">All Boards</RouterLink> -->
                <RouterLink to="/products/aero-pro">Aero Pro</RouterLink>
              </div>

              <div>
                <Label>Accessories</Label>
                <!-- <RouterLink to="/accessories">All Accessories</RouterLink> -->
                <RouterLink to="/products/600w-fast-charger">600W Fast Charger</RouterLink>
              </div>

              <div>
                <Label>Info</Label>
                <RouterLink to="/policies">Policies</RouterLink>
                <RouterLink to="/terms">Terms & Conditions</RouterLink>
                <RouterLink to="/about">About Us</RouterLink>
              </div>

              <div>
                <Label>Contact</Label>
                <RouterLink to="/support">Support</RouterLink>
              </div>
            </nav>
          </PopoverContent>
        </Popover>

        <CartButton />
      </div>
    </header>
  </div>
</template>

<style>
.header-container {
  --top-gap: 24px;
  @apply absolute left-0 right-0 top-[var(--top-gap)] pointer-events-none md:h-[calc(var(--h)_-_var(--top-gap))];
}

header {
  @apply z-50 md:sticky top-0 flex justify-between md:grid md:grid-cols-[1fr_auto_1fr] w-full h-12 px-6 md:px-12 transition-[top];

  > * {
    display: flex;
    align-items: center;
    pointer-events: auto;

    &:first-child {
      justify-self: start;
    }

    &:last-child {
      justify-self: end;
    }
  }
}

.navigation {
  @apply hidden md:block;

  .nav-viewport-container {
    top: calc(100% - 25px);
  }

  &[data-open='true'] {
    ul::before {
      bottom: calc(-1px * var(--tt) + 1.5rem + 1px);
    }
  }

  .nav-trigger,
  .nav-content > * {
    @apply px-4 py-2 hover:bg-black/5 rounded-[10px];
  }

  ul {
    @apply flex relative p-4;

    &::before {
      content: '';
      @apply absolute left-0 right-0 top-0 bottom-0 -z-10;

      backdrop-filter: blur(18px) saturate(1.5);
      clip-path: inset(12px 12px 12px 12px round 12px);
      transition: background 0.4s;
      will-change: opacity;
      background: rgba(255, 255, 255, 0.6);
    }

    li {
      @apply whitespace-nowrap;
    }
  }
}

.small-nav {
  @apply flex flex-col;

  > div {
    @apply flex flex-col py-2;

    > label {
      @apply font-semibold text-lg mb-2;
    }

    > a {
      @apply py-1;

      &:hover {
        @apply text-accent;
      }

      &:first-child {
        @apply pt-0;
      }

      &:last-child {
        @apply pb-0;
      }
    }
  }
}
</style>
