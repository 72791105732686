<template>
  <svg viewBox="0 0 56.626 50">
    <defs>
      <clipPath id="clippath">
        <rect fill="none" width="56.626" height="50" />
      </clipPath>
    </defs>
    <g>
      <g clip-path="url(#clippath)">
        <path
          fill="currentColor"
          d="M26.267,17.857h4.097c.646,0,1.243.345,1.567.905l7.203,12.476c.323.56.323,1.25,0,1.809l-2.049,3.548c-.323.56-.92.905-1.567.905h-14.406c-.646,0-1.244-.345-1.567-.905l-2.048-3.548c-.323-.559-.323-1.249,0-1.809l7.203-12.476c.323-.56.921-.905,1.567-.905"
        />
        <path
          fill="currentColor"
          d="M38.618,44.643h-20.606c-1.28,0-2.463-.683-3.103-1.791l-5.148-8.918c-.64-1.108-.64-2.474,0-3.582l10.303-17.846c.64-1.109,1.823-1.792,3.103-1.792h10.296c1.28,0,2.463.683,3.103,1.792l10.303,17.846c.64,1.108.64,2.474,0,3.582l-5.148,8.918c-.64,1.108-1.823,1.791-3.103,1.791M33.461,0h-10.297c-1.28,0-2.463.683-3.103,1.792L.479,35.708c-.64,1.109-.64,2.475,0,3.584l5.149,8.916c.64,1.109,1.823,1.792,3.103,1.792h39.164c1.28,0,2.463-.683,3.102-1.792l5.149-8.916c.64-1.109.64-2.475,0-3.584L36.564,1.792c-.64-1.109-1.823-1.792-3.103-1.792"
        />
      </g>
    </g>
  </svg>
</template>
