import * as Sentry from '@sentry/vue';
import { usePageContext } from 'vike-vue/usePageContext';

export function sentryBrowserConfig(): NonNullable<ReturnType<typeof Sentry.init>> | null {
  if (import.meta.env.PROD) {
    const client = Sentry.init({
      // @ts-ignore
      app: usePageContext().app,
      dsn: import.meta.env.PUBLIC_ENV__SENTRY_DSN,
      environment: 'production-frontend',
      //enabled: import.meta.env.DEV ? false : true,
      integrations: [Sentry.replayIntegration()],
      autoSessionTracking: !!globalThis?.window?.document, // disable autoSessionTracking in SSR

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 1.0,
      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      // replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    return client ?? null;
  }

  return null;
}
