<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  decimal?: boolean;
  value?: { amount?: string | number; currencyCode?: string };
}>();

const format = computed(() => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: props.value?.currencyCode || 'USD',
    minimumFractionDigits: props.decimal ? 2 : 0,
  });
});

function toNumber(value?: string | number) {
  return typeof value === 'string' ? Number(value) : value;
}

const price = computed(() => format.value.format(toNumber(props.value?.amount) ?? 0));

function curr(value?: string) {
  switch (value) {
    case 'USD':
      return 'US';
    default:
      return '';
  }
}
</script>

<template>{{ curr(props.value?.currencyCode) }}{{ price }}</template>
