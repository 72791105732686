<script setup lang="ts">
import { cn } from '@/lib/utils.ts';
import {
  NavigationMenuContent,
  type NavigationMenuContentEmits,
  type NavigationMenuContentProps,
  useForwardPropsEmits,
} from 'radix-vue';
import { type HTMLAttributes, computed } from 'vue';

const props = defineProps<NavigationMenuContentProps & { class?: HTMLAttributes['class'] }>();

const emits = defineEmits<NavigationMenuContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <NavigationMenuContent
    v-bind="forwarded"
    class="nav-content"
    :class="cn('flex h-full w-full flex-wrap justify-stretch *:flex-[1_0_auto] *:text-center', props.class)"
  >
    <slot />
  </NavigationMenuContent>
</template>
