import type {
  MoneyV2,
  Product as ProductQuery,
  ProductVariant as ProductVariantQuery,
} from '@/types/shopify/generated.ts';
import { $, $$, query } from '@/types/shopify/generated.ts';
import type { ReturnWriteable } from '@/types/util.ts';
import type { ResultOf } from '@graphql-typed-document-node/core';
import { getShopifyClient } from './index.ts';

export const DEFAULT_OPTION_TITLE = 'Title';
export const DEFAULT_OPTION_VALUE_TITLE = 'Default Title';

function _money(s: MoneyV2) {
  return [s.amount, s.currencyCode] as const;
}

const money = _money as ReturnWriteable<typeof _money>;

function _variantFields(v: ProductVariantQuery) {
  return [v.id, v.title, v.price(money), v.compareAtPrice(money), v.image((i) => [i.url({}), i.altText])] as const;
}

const variantFields = _variantFields as ReturnWriteable<typeof _variantFields>;

function _productFields(p: ProductQuery) {
  return [
    p.id,
    p.title,
    p.handle,
    p.descriptionHtml,
    p.encodedVariantExistence,
    p.encodedVariantAvailability,
    p.priceRange((pr) => [pr.minVariantPrice(money)]),
    p.compareAtPriceRange((pr) => [pr.minVariantPrice(money)]),
    p.images({ first: 50 }, (i) => [i.nodes((n) => [n.url({}), n.altText])]),
    // p.variants({ first: 10 }, (v) => [v.nodes(variantFields)]),
    p.options((o) => [o.id, o.name, o.optionValues((v) => [v.id, v.name])]),
  ] as const;
}

const productFields = _productFields as ReturnWriteable<typeof _productFields>;

const getProductByIdQuery = query((q) => [q.product({ id: $$('id') }, productFields)]);

const getProductByHandleQuery = query((q) => [q.product({ handle: $$('handle') }, productFields)]);

const getProductsQuery = query((q) => [q.products({ first: $('first') }, (p) => [p.nodes(productFields)])]);

const getVariantsByOptionsQuery = query((q) => [
  q.product({ id: $$('id') }, (p) => [
    p.variantBySelectedOptions({ selectedOptions: $$('selectedOptions') }, variantFields),
  ]),
]);

export type Product = NonNullable<ResultOf<typeof getProductByIdQuery>['product']>;
// export type ProductVariant = Product['variants']['nodes'][number];

export type ImageNode = NonNullable<Product['images']['nodes'][number]>;

export async function getProductById(id: string): Promise<Product | null> {
  const client = getShopifyClient();

  const res = await client.query(getProductByIdQuery, { id });

  if (res.error) {
    console.error(res.error);
  }

  return res.data?.product ?? null;
}

export async function getProductByHandle(handle: string): Promise<Product | null> {
  const client = getShopifyClient();

  const res = await client.query(getProductByHandleQuery, { handle });

  if (res.error) {
    console.error(res.error);
  }

  return res.data?.product ?? null;
}

export async function getProducts(first: number): Promise<Product[] | null> {
  const client = getShopifyClient();

  const res = await client.query(getProductsQuery, { first });

  if (res.error) {
    console.error(res.error);
  }

  return res.data?.products.nodes ?? [];
}

export type Option = NonNullable<Product['options'][number]>;
export type OptionValue = Option['optionValues'][number];

export type VariantByOptions = NonNullable<
  NonNullable<ResultOf<typeof getVariantsByOptionsQuery>['product']>['variantBySelectedOptions']
>;

const optionVariantCache = new Map<string, VariantByOptions>();

export async function getVariantByOptions(
  id: string,
  selectedOptions: { name: string; value: string }[],
): Promise<VariantByOptions | null> {
  const optionsString = selectedOptions
    .map((o) => `${o.name}:${o.value}`)
    .sort()
    .join('_');
  const cacheKey = `${id}_${optionsString}`;

  const cached = optionVariantCache.get(cacheKey);
  if (cached) {
    return cached;
  }

  const client = getShopifyClient();

  const res = await client.query(getVariantsByOptionsQuery, { id, selectedOptions });

  if (res.error) {
    console.error(res.error);
  }

  const variant = res.data?.product?.variantBySelectedOptions ?? null;

  if (variant) {
    optionVariantCache.set(cacheKey, variant);
  }

  return variant;
}

export function decodeVariantAvailability(encoded: string): number[][] {
  const tokenizer = /[ :,-]/g;

  let token = tokenizer.exec(encoded);
  let index = 0;

  let depth = 0;
  let rangeStart: number | null = null;

  const results: number[][] = [];
  const currentResult: number[] = [];

  while (token) {
    const operation = token[0];
    const optionValueIndex = Number.parseInt(encoded.slice(index, token.index)) || 0;

    if (rangeStart !== null) {
      while (rangeStart < optionValueIndex) {
        currentResult[depth] = rangeStart;
        results.push([...currentResult]);
        rangeStart++;
      }

      rangeStart = null;
    }

    currentResult[depth] = optionValueIndex;

    switch (operation) {
      case '-':
        rangeStart = optionValueIndex;
        break;
      case ':':
        depth++;
        break;
      default: {
        if (operation === ' ' || (operation === ',' && encoded[token.index - 1] !== ',')) {
          results.push([...currentResult]);
        }

        if (operation === ',') {
          currentResult.pop();
          depth--;
        }

        break;
      }
    }

    index = tokenizer.lastIndex;
    token = tokenizer.exec(encoded);
  }

  const lastRangeStartIndex = encoded.lastIndexOf('-');
  if (rangeStart !== null && lastRangeStartIndex > 0) {
    const finalValueIndex = Number.parseInt(encoded.substring(lastRangeStartIndex + 1)) || 0;
    while (rangeStart <= finalValueIndex) {
      currentResult[depth] = rangeStart;
      results.push([...currentResult]);
      rangeStart++;
    }
  }

  return results;
}

export function isVariantAvailable(decodedVariants: number[][], combination: number[]): boolean {
  return decodedVariants.some((variant) => {
    return variant.every((value, index) => value === combination[index]);
  });
}
