<script setup lang="ts">
import { cn } from '@/lib/utils.ts';
import {
  NavigationMenuRoot,
  type NavigationMenuRootEmits,
  type NavigationMenuRootProps,
  useForwardPropsEmits,
} from 'radix-vue';
import { type HTMLAttributes, computed, useTemplateRef, watch } from 'vue';
import NavigationMenuViewport from './NavigationMenuViewport.vue';

const props = defineProps<NavigationMenuRootProps & { class?: HTMLAttributes['class'] }>();

type Awa = {
  size: [number];
};

const emits = defineEmits<NavigationMenuRootEmits & Awa>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const viewport = useTemplateRef('viewport');

watch(
  () => forwarded.value.modelValue,
  () => {
    requestAnimationFrame(() => {
      emits('size', viewport.value?.$el.children[0]?.clientHeight ?? 0);
    });
  },
);
</script>

<template>
  <NavigationMenuRoot
    v-bind="forwarded"
    :class="cn('relative z-10 flex max-w-max flex-1 items-center justify-center', props.class)"
  >
    <slot />
    <NavigationMenuViewport ref="viewport" />
  </NavigationMenuRoot>
</template>
