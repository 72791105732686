import { type Cart, addItemsToCart, createCart, getCart, removeItemsFromCart, updateCart } from '@/lib/shopify/cart.ts';
import type { CartLineInput, CartLineUpdateInput } from '@/types/shopify/generated.ts';
import { useLocalStorage, whenever } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useShopify = defineStore('shopify', () => {
  const cartId = useLocalStorage<string | null>('cartId', null);
  const cart = ref<Cart | null>(null);

  const cartUpdating = ref(false);

  whenever(
    cartId,
    async (value) => {
      if (!cart.value || cart.value.id !== value) {
        cart.value = await getCart(value);
      }
    },
    { immediate: true },
  );

  async function reloadCart() {
    if (cartId.value) {
      cart.value = null;
      cart.value = await getCart(cartId.value);
    }
  }

  watch(cart, (value) => {
    if (value) {
      cartId.value = value.id;
    }
  });

  async function addToCart(merchandiseId: string, quantity: number) {
    cartUpdating.value = true;

    const lines: CartLineInput[] = [{ merchandiseId, quantity }];

    if (cartId.value) {
      cart.value = await addItemsToCart(cartId.value, lines);
    } else {
      cart.value = await createCart(lines);
    }

    cartUpdating.value = false;
  }

  async function updateQuantity(lineId: string, merchandiseId: string, newQuantity: number) {
    if (!cart.value) return;

    cartUpdating.value = true;

    const lines: CartLineUpdateInput[] = [{ id: lineId, merchandiseId, quantity: newQuantity }];

    cart.value = await updateCart(cart.value.id, lines);

    cartUpdating.value = false;
  }

  async function removeFromCart(lineId: string) {
    if (!cart.value) return;

    cartUpdating.value = true;

    cart.value = await removeItemsFromCart(cart.value.id, [lineId]);

    cartUpdating.value = false;
  }

  return {
    cartId,
    cart,

    reloadCart,
    addToCart,
    removeFromCart,
    updateQuantity,

    cartUpdating,
  };
});
