import { until, useElementVisibility } from '@vueuse/core';
import type { Directive } from 'vue';

export const fadeInDirective: Directive<HTMLElement, never> = {
  beforeMount(el) {
    el.classList.add('invisible');
  },
  mounted(el) {
    const visible = useElementVisibility(el);

    (async () => {
      await until(visible).toMatch((v) => v === true);

      el.classList.remove('invisible');
      el.classList.add('fade-in');
    })();
  },
};
