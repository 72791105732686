<template>
  <svg viewBox="0 0 150 28.079">
    <defs>
      <clipPath id="clippath">
        <rect fill="none" width="150" height="28.079" />
      </clipPath>
    </defs>
    <g clip-path="url(#clippath)" fill="currentColor">
      <path
        d="M14.752,10.028h2.301c.363,0,.698.194.88.508l4.045,7.007c.181.314.181.701,0,1.016l-1.15,1.992c-.182.315-.517.508-.881.508h-8.09c-.363,0-.698-.193-.88-.508l-1.15-1.992c-.182-.315-.182-.702,0-1.016l4.045-7.007c.181-.314.517-.508.88-.508"
      />
      <path
        d="M21.688,25.071h-11.572c-.719,0-1.383-.384-1.743-1.006l-2.891-5.008c-.359-.622-.359-1.39,0-2.012l5.786-10.022c.36-.623,1.024-1.006,1.743-1.006h5.782c.719,0,1.383.383,1.743,1.006l5.786,10.022c.359.622.359,1.39,0,2.012l-2.891,5.008c-.36.622-1.024,1.006-1.743,1.006M18.792,0h-5.782C12.291,0,11.626.384,11.267,1.006L.27,20.053c-.359.623-.359,1.39,0,2.013l2.891,5.007c.36.623,1.024,1.006,1.743,1.006h21.994c.719,0,1.383-.383,1.742-1.006l2.892-5.007c.359-.623.359-1.39,0-2.013L20.534,1.006C20.175.384,19.511,0,18.792,0"
      />
      <path
        d="M47.163,15.152c2.039,0,2.665-.789,2.665-2.692s-.626-2.637-2.665-2.637h-4.296c-.952,0-1.278.326-1.278,1.278v4.051h5.574ZM36.695,25.049V8.953c0-2.039,1.115-2.936,2.882-2.936h10.115c4.214,0,5.138,2.637,5.138,6.443s-.924,6.499-5.138,6.499h-8.103v6.09h-4.894Z"
      />
      <path
        d="M61.494,17.001h5.627l-2.419-6.036c-.109-.299-.191-.598-.381-.598s-.299.299-.408.598l-2.419,6.036ZM60.569,8.029c.625-1.496,1.414-2.23,3.752-2.23,1.713,0,2.964.381,3.725,2.23l7.124,17.02h-5.139l-1.713-4.35h-8.021l-1.713,4.35h-5.111l7.096-17.02Z"
      />
      <path
        d="M76.123,8.953c0-2.039,1.115-2.936,2.882-2.936h10.169c3.942,0,5.084,2.311,5.084,6.253,0,3.317-.815,6.553-4.323,6.553h-1.359v.136l6.063,6.09h-6.009l-4.296-4.949c-.707-.788-1.713-1.631-1.713-2.691v-.435c0-1.387,1.033-1.713,2.801-1.713h1.604c1.821,0,2.229-.925,2.229-2.828,0-1.957-.571-2.61-2.419-2.61h-4.541c-.952,0-1.278.326-1.278,1.278v13.948h-4.894V8.953Z"
      />
      <path
        d="M96.489,21.243h10.549c1.306,0,1.36-.843,1.36-1.305,0-.653-.326-1.197-1.468-1.55l-7.45-2.229c-2.474-.734-3.208-2.665-3.208-5.33,0-2.854,1.25-4.812,4.404-4.812h11.746v3.807h-9.843c-.707,0-1.332.272-1.332,1.25,0,.734.381,1.251,1.523,1.605l6.688,1.957c2.828.816,3.943,2.23,3.943,5.356,0,3.018-1.115,5.057-4.351,5.057h-12.561v-3.806Z"
      />
      <path
        d="M131.673,9.714h-9.788c-.952,0-1.278.326-1.278,1.278v2.637h10.822v3.535h-10.822v2.909c0,.952.326,1.278,1.278,1.278h9.897v3.698h-13.187c-1.767,0-2.882-.87-2.882-2.909v-13.187c0-2.039,1.115-2.937,2.882-2.937h13.078v3.698Z"
      />
      <path
        d="M141.87,25.049c-6.389,0-8.184-3.534-8.184-9.516s1.795-9.516,8.184-9.516h7.858v3.806h-5.438c-4.35,0-5.601,1.088-5.601,5.71s1.251,5.71,5.601,5.71h5.71v3.806h-8.13Z"
      />
    </g>
  </svg>
</template>
