import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function gql(strings: TemplateStringsArray, ...keys: string[]) {
  return strings
    .reduce((acc, str, i) => {
      return acc + str + (keys[i] || '');
    }, '')
    .trim();
}

export type PlausibleOptions = {
  callback?: () => void;
  props?: Record<string, unknown>;
};

export function sendPlausible(eventName: string, options?: PlausibleOptions) {
  // @ts-ignore
  if (typeof window !== 'undefined' && window.plausible) {
    // @ts-ignore
    window.plausible(eventName, options);
  }
}
