<script setup lang="ts">
import Price from '@/components/Price.vue';
import Button from '@/components/ui/button/Button.vue';
import {
  NumberField,
  NumberFieldContent,
  NumberFieldDecrement,
  NumberFieldIncrement,
  NumberFieldInput,
} from '@/components/ui/number-field';
import type { CartLineItem } from '@/lib/shopify/cart.ts';
import { DEFAULT_OPTION_VALUE_TITLE } from '@/lib/shopify/product.ts';
import { sendPlausible } from '@/lib/utils';
import { useShopify } from '@/stores/shopify.ts';
import { watchDebounced } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

const MAX_QUANTITY = 1000000;

const props = defineProps<{
  line: CartLineItem;
  updating: boolean;
}>();

const quantity = ref(props.line.quantity);

const shopify = useShopify();

async function onQuantityUpdate() {
  if (!shopify.cart) return;

  if (props.line.quantity === quantity.value) {
    return;
  }

  await shopify.updateQuantity(props.line.id, props.line.merchandise.id, quantity.value);
}

watchDebounced(quantity, onQuantityUpdate, { debounce: 1000 });

watch(
  () => props.line.quantity,
  (v) => {
    quantity.value = v;
  },
  { immediate: true },
);

function remove() {
  shopify.removeFromCart(props.line.id);
  sendPlausible('Removed from cart', {
    props: {
      productId: props.line.merchandise.product.id,
      product: props.line.merchandise.product.title,
      variantId: props.line.merchandise.id,
      variant: props.line.merchandise.title,
    },
  });
}

const hasOptions = computed(
  () =>
    props.line.merchandise.selectedOptions.length > 0 &&
    props.line.merchandise.selectedOptions[0].value !== DEFAULT_OPTION_VALUE_TITLE,
);
</script>

<template>
  <div class="flex gap-4 p-4 border border-neutral-400 rounded-md">
    <div class="aspect-square w-16">
      <img :src="line.merchandise.image?.url" :alt="line.merchandise.image?.altText ?? 'Product image'" />
    </div>
    <div class="flex flex-col w-full">
      <div class="flex justify-between">
        <h4 class="font-semibold">{{ line.merchandise.product.title }}</h4>

        <Button class="pr-0" variant="link" size="sm" @click="remove">Remove</Button>
      </div>

      <div class="flex flex-col gap-4 items-start">
        <div v-if="hasOptions" class="flex flex-wrap gap-1">
          <span
            v-for="option in line.merchandise.selectedOptions"
            class="text-xs font-semibold border border-neutral-400 rounded-md px-2"
          >
            {{ option.name }}: {{ option.value }}
          </span>
        </div>

        <div class="flex gap-4 items-center justify-between w-full">
          <NumberField v-model="quantity" :max="MAX_QUANTITY" class="w-32">
            <NumberFieldContent>
              <NumberFieldDecrement />
              <NumberFieldInput />
              <NumberFieldIncrement />
            </NumberFieldContent>
          </NumberField>

          <span class="font-semibold" :class="{ 'text-neutral-500': updating }">
            <Price :value="line.cost.totalAmount" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
