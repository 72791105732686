import type { Directive } from 'vue';

const INVALID_CHAR_REGEX = /[^0-9a-zA-Z?/:@.~!*+=\- ]/g;

// set id attribute on element, and wrap children in anchor link to #id
export const anchorDirective: Directive<HTMLElement, string> = {
  mounted(el, binding) {
    // if no value, use text content as id
    let id = binding.value;
    if (!id) {
      id =
        el.textContent
          ?.trim()
          .toLowerCase()
          .replace(INVALID_CHAR_REGEX, '')
          .replace(/(\s|-+)+/g, '-') || '';
    }

    el.id = id;

    const anchor = document.createElement('a');
    anchor.href = `#${id}`;

    while (el.firstChild) {
      anchor.appendChild(el.firstChild);
    }

    el.appendChild(anchor);
  },
};
