<script setup lang="ts">
import ExternalLink from '@/components/ExternalLink.vue';
import Price from '@/components/Price.vue';
import CartItem from '@/components/cart/CartItem.vue';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { sendPlausible } from '@/lib/utils';
import { useShopify } from '@/stores/shopify.ts';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue';
import { computed } from 'vue';
import LoaderCircle from '~icons/lucide/loader-circle';

const shopify = useShopify();

const cartEmpty = computed(() => {
  const items = shopify.cart?.lines.nodes.length;
  return items === 0 || items === undefined;
});

function checkout(event: MouseEvent) {
  event.preventDefault();

  if (!shopify.cart?.checkoutUrl) {
    return;
  }

  sendPlausible('Checkout', {
    props: {
      cart: shopify.cart?.lines.nodes.map((line) => ({
        productId: line.merchandise.product.id,
        product: line.merchandise.product.title,
        variantId: line.merchandise.id,
        variant: line.merchandise.title,
        quantity: line.quantity,
      })),
    },
  });

  window.location.href = shopify.cart.checkoutUrl;
}
</script>

<template>
  <div class="flex flex-col gap-3 min-h-0 h-full">
    <div v-if="cartEmpty" class="flex h-64 items-center justify-center">
      <p>Your cart is empty.</p>
    </div>

    <div v-else class="flex flex-col gap-4 h-full">
      <OverlayScrollbarsComponent defer class="h-full">
        <div class="flex flex-col gap-3 px-3">
          <CartItem v-for="line in shopify.cart?.lines.nodes" :key="line.id" :line :updating="shopify.cartUpdating" />
        </div>
      </OverlayScrollbarsComponent>

      <div class="flex flex-col gap-4 p-2">
        <div class="flex items-center justify-between">
          <Label class="text-xl font-semibold">Subtotal:</Label>
          <h3 class="text-2xl" :class="{ 'text-neutral-400': shopify.cartUpdating }">
            <Price decimal :value="shopify.cart?.cost.subtotalAmount" />
          </h3>
        </div>

        <ExternalLink :href="shopify.cart?.checkoutUrl ?? '#'" @click="checkout">
          <Button class="w-full text-xl" size="lg" variant="accent" :disabled="shopify.cartUpdating || cartEmpty">
            <span class="plausible-event-name=cart-checkout">Checkout</span>
            <LoaderCircle v-if="shopify.cartUpdating" class="ml-2 animate-spin" />
          </Button>
        </ExternalLink>
      </div>
    </div>
  </div>
</template>
