import { ref, watch, type Ref } from 'vue';

const overlayCount = ref(0);

export function useOverlayCount() {
  return overlayCount;
}

export function watchOverlayBool(b: Ref<boolean>) {
  return watch(b, (value) => {
    overlayCount.value += value ? 1 : -1;
  });
}
