import { ref, type Ref } from 'vue';

export const breakpoints = {
  xs: 320,
  mxs: 375,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export type Breakpoint = keyof typeof breakpoints;

const breakpoint = ref<Breakpoint>('sm');

function onResize() {
  const width = window.innerWidth;
  const breakpointsArray = Object.entries(breakpoints);
  for (const [key, value] of breakpointsArray) {
    if (width >= value) {
      breakpoint.value = key as Breakpoint;
    }
  }
}

if (typeof window !== 'undefined') {
  window.addEventListener('resize', onResize);
}

export function useBreakpoint(): Ref<Breakpoint> {
  return breakpoint;
}
