<script setup lang="ts">
import { useBreakpoint } from '@/composables/breakpoint.ts';
import { onMounted, onUnmounted, ref, useTemplateRef, watch } from 'vue';

const CHANGE_INTERVAL_MS = 1500;

const rightWidth = ref(300);
const rightHeight = ref(0);

const left = useTemplateRef('left');
const right = useTemplateRef('right');

let activeElementIndex = 0;
let elements: { el: HTMLElement; width: number }[] = [];

function loadElements() {
  if (!right.value) {
    return;
  }

  elements = [];
  for (const el of right.value.children as HTMLCollectionOf<HTMLElement>) {
    elements.push({ el, width: el.clientWidth });
  }
}

// px
const EXTRA_PADDING = 4;

function calculateWidths() {
  if (!left.value || !right.value) {
    return;
  }

  // let the children expand to their full width and measure
  for (const element of elements) {
    const initialStyle = element.el.style.cssText;
    const initialState = element.el.getAttribute('data-state');

    element.el.style.position = 'absolute';
    element.el.style.visibility = 'hidden';
    element.el.style.width = 'auto';
    element.el.style.height = 'auto';
    element.el.removeAttribute('data-state');

    element.width = element.el.clientWidth;

    if (initialStyle === '') {
      element.el.removeAttribute('style');
    } else {
      element.el.style.cssText = initialStyle;
    }

    if (initialState) {
      element.el.setAttribute('data-state', initialState);
    }
  }

  rightWidth.value = elements[activeElementIndex].width + EXTRA_PADDING;
  rightHeight.value = left.value?.children[0].clientHeight ?? 0;
}

function doMarquee() {
  if (elements.length === 0) {
    return;
  }

  const previousElementIndex = activeElementIndex;
  activeElementIndex = (activeElementIndex + 1) % elements.length;
  const nextElementIndex = (activeElementIndex + 1) % elements.length;

  const activeElement = elements[activeElementIndex];
  rightWidth.value = activeElement.width;

  // set top to move active element into view, the previous element above the view, and all others below and hidden
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    if (i === previousElementIndex) {
      element.el.setAttribute('data-state', 'prev');
    } else if (i === activeElementIndex) {
      element.el.setAttribute('data-state', 'active');
    } else if (i === nextElementIndex) {
      element.el.setAttribute('data-state', 'next');
    } else {
      element.el.setAttribute('data-state', 'none');
    }
  }
}

let marqueeInterval: NodeJS.Timeout | number | null = null;

function startMarquee() {
  if (marqueeInterval !== null) {
    clearInterval(marqueeInterval);
  }

  marqueeInterval = setInterval(doMarquee, CHANGE_INTERVAL_MS);
}

const breakpoint = useBreakpoint();

onMounted(() => {
  loadElements();
  calculateWidths();
  startMarquee();
  doMarquee();

  // calculate widths again after a second to make sure everything is loaded
  setTimeout(() => {
    calculateWidths();
  }, 1000);

  // todo: maybe mutation observer to watch for changes in children
});

watch(breakpoint, calculateWidths);

onUnmounted(() => {
  if (marqueeInterval !== null) {
    clearInterval(marqueeInterval);
  }
});
</script>

<template>
  <div class="vertical-marquee">
    <div ref="left">
      <slot name="left" />
    </div>
    <div ref="right" :style="{ height: `${rightHeight}px`, width: `${rightWidth}px` }" class="right">
      <slot name="right" />
    </div>
  </div>
</template>

<style>
.vertical-marquee {
  @apply flex flex-row gap-2 md:gap-3 lg:gap-6;

  .right {
    @apply relative overflow-hidden;

    transition: width 300ms ease-in-out;

    > * {
      @apply absolute top-0 left-0;

      top: 100%;

      transition: top 300ms ease-in-out;

      &[data-state='active'] {
        top: 0;
      }

      &[data-state='prev'] {
        top: -100%;
      }

      &[data-state='next'] {
        top: 100%;
      }

      &[data-state='none'] {
        top: 200%;
        display: none;
      }
    }
  }
}
</style>
