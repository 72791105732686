import { Client, cacheExchange, fetchExchange } from '@urql/vue';

function clientUrl(domain: string, apiVersion: string) {
  return `https://${domain}/api/${apiVersion}/graphql.json`;
}

function createClient() {
  return new Client({
    exchanges: [cacheExchange, fetchExchange],
    url: clientUrl('checkout.rideparsec.com', '2024-10'),
    fetchOptions: () => {
      return {
        headers: {
          'X-Shopify-Storefront-Access-Token': 'fa78d88ef671634b1d4b94970dc3597e',
        },
      };
    },
  });
}

// "storefront": {
//   "domain": "work-louder.myshopify.com",
//   "storefrontAccessToken": "210ebf1f49ed9f0c0ae744be719b03ef"
// }

const client = createClient();

export function getShopifyClient(): Client {
  return client;
}
