import type { CartLineInput, CartLineUpdateInput, Cart as CartQuery, MoneyV2 } from '@/types/shopify/generated.ts';
import { $$, mutation, query } from '@/types/shopify/generated.ts';
import type { ReturnWriteable } from '@/types/util.ts';
import type { ResultOf } from '@graphql-typed-document-node/core';
import { getShopifyClient } from './index.ts';

function _money(s: MoneyV2) {
  return [s.amount, s.currencyCode] as const;
}

const money = _money as ReturnWriteable<typeof _money>;

function _cartItemFields(c: CartQuery) {
  return [
    c.id,
    c.totalQuantity,
    c.checkoutUrl,
    c.lines({ first: 10 }, (l) => [
      l.nodes((n) => [
        n.id,
        n.quantity,
        n.merchandise((m) => [
          m.$on('ProductVariant', (v) => [
            v.id,
            v.title,
            v.price(money),
            v.image((i) => [i.url({}), i.altText]),
            v.product((p) => [p.title, p.id]),
            v.selectedOptions((o) => [o.name, o.value]),
          ]),
        ]),
        n.cost((c) => [c.totalAmount(money), c.subtotalAmount(money)]),
      ]),
    ]),
    c.cost((c) => [c.totalAmount(money), c.subtotalAmount(money)]),
  ] as const;
}

const cartItemFields = _cartItemFields as ReturnWriteable<typeof _cartItemFields>;

const createCartMutation = mutation((m) => [m.cartCreate({ input: $$('input') }, (c) => [c.cart(cartItemFields)])]);

const getCartQuery = query((q) => [q.cart({ id: $$('id') }, cartItemFields)]);

const cartLinesUpdateMutation = mutation((m) => [
  m.cartLinesUpdate({ cartId: $$('cartId'), lines: $$('lines') }, (u) => [u.cart(cartItemFields)]),
]);

const cartLinesAddMutation = mutation((m) => [
  m.cartLinesAdd({ cartId: $$('cartId'), lines: $$('lines') }, (a) => [a.cart(cartItemFields)]),
]);

const cartLinesRemoveMutation = mutation((m) => [
  m.cartLinesRemove({ cartId: $$('cartId'), lineIds: $$('lineIds') }, (r) => [r.cart(cartItemFields)]),
]);

export async function getCart(id: string) {
  console.debug('fetching cart with id', id);

  const client = getShopifyClient();

  const res = await client.query(getCartQuery, { id });

  if (res.error) {
    console.error(res.error);
  }

  return res.data?.cart ?? null;
}

export type Cart = NonNullable<ResultOf<typeof getCartQuery>['cart']>;
export type CartLineItem = Cart['lines']['nodes'][number];

export async function createCart(lines: CartLineInput[]): Promise<Cart | null> {
  console.debug('creating new cart with items', lines);

  const client = getShopifyClient();

  const res = await client.mutation(createCartMutation, { input: { lines } });

  if (res.error) {
    console.error(res.error);
  }

  return res.data?.cartCreate?.cart ?? null;
}

export async function updateCart(cartId: string, lines: CartLineUpdateInput[]): Promise<Cart | null> {
  console.debug('modifying items in cart', cartId, lines);

  const client = getShopifyClient();

  const res = await client.mutation(cartLinesUpdateMutation, { cartId, lines });

  if (res.error) {
    console.error(res.error);
  }

  return res.data?.cartLinesUpdate?.cart ?? null;
}

export async function addItemsToCart(cartId: string, lines: CartLineInput[]): Promise<Cart | null> {
  console.debug('adding items to cart', cartId, lines);

  const client = getShopifyClient();

  const res = await client.mutation(cartLinesAddMutation, { cartId, lines });

  if (res.error) {
    console.error(res.error);
  }

  return res.data?.cartLinesAdd?.cart ?? null;
}

export async function removeItemsFromCart(cartId: string, lineIds: string[]): Promise<Cart | null> {
  console.debug('removing items from cart', cartId, lineIds);

  const client = getShopifyClient();

  const res = await client.mutation(cartLinesRemoveMutation, { cartId, lineIds });

  if (res.error) {
    console.error(res.error);
  }

  return res.data?.cartLinesRemove?.cart ?? null;
}
